import React from 'react';

function Layout(props) {
  const loggedIn = props.loggedIn;
  const currentUser = props.currentUser;
  
  return (
    <div>
      {React.cloneElement(props.children, { loggedIn, currentUser })}
    </div>
  );
}

export default Layout;
