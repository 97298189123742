import React from 'react';
import HomeStyles from './Home.module.css';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ZoomIcon from '../../images/small-zoom-icon-logo.png';
import PolyIcon from '../../images/small-poly-logo.png';

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Typography variant="caption" component="div" color="white">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

function Home() {
  const [token, setToken] = React.useState({});
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    localStorage.setItem('ztoken', params.get('code'));
    setToken({ code: params.get('code') });
  }, []);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress(
        prevProgress => (prevProgress >= 100 ? 100 : prevProgress + 10)
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  React.useEffect(
    () => {
      const windowUrl = window.location.search;
      const params = new URLSearchParams(windowUrl);
      const code = params.get('code');
      if (progress === 100 && code) {
        document.location.href = `/device`;
      }
    },
    [progress]
  );

  return (
    <div className={HomeStyles.layout}>
      <header className={HomeStyles.header}>
        keentek
        <br />
        <a href="https://zoom.us/oauth/authorize?response_type=code&client_id=hyVhEx4R7WEITsvv08FEA&redirect_uri=https%3A%2F%2Fkeentek.app%2F">
          {token.code ? (
            <div className={HomeStyles.scan}>
              Scanning network..<br />
              <br />
              <CircularProgressWithLabel value={progress} />
              {progress === 100 && <h2>Loading networked devices</h2>}
            </div>
          ) : (
            <Stack direction="column" spacing={2}>
              <Button variant="contained" startIcon={<img src={ZoomIcon} width="25px" height="25px" alt="ZoomIcon" />}>
                Scan devices with Zoom
              </Button>
              <Button variant="contained" startIcon={<img src={PolyIcon} width="55px" height="25px" alt="PolyIcon" />}>
                Scan devices with Poly Lens
              </Button>
            </Stack>
          )}
        </a>
      </header>
    </div>
  );
}

export default Home;
