import React from 'react';

function Login(props) {
  return (
    <div>
      Login
    </div>
  );
}

export default Login;
